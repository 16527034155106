import { Route, Routes } from "react-router-dom";

// import { lazy, Suspense } from "react";
// import Home from "./pages/Home";
// import About from "./pages/About";
// import NewsLayout from "./pages/NewsLayout";
// import News from "./pages/News";
// import SingleNews from "./pages/SingleNews";
// import Partner from "./pages/Partner";
// import Vacancy from "./pages/Vacancy";
// import Accessories from "./pages/Accessories";
// import Product from "./pages/products/Product";
// import Calculator from "./pages/calculator/Calculator";
// import Analytics from "./pages/products/Analytics";
// import Services from "./pages/products/Services";

// Lazy load the product components
// import CareMgt from "./pages/products/CareMgt";
// import ResourceMgt from "./pages/products/ResourceMgt";
// import PatientMonitoring from "./pages/products/PatientMonitoring";
// import SystemIntegration from "./pages/products/SystemIntegration";
// import SmartRostering from "./pages/products/SmartRostering";
// import DigitalTech from "./pages/products/DigitalTech";
import ChatIcon from "./components/ChatIcon";
import CookieConsent from "react-cookie-consent";
import PageNotFound from "./pages/PageNotFound";

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// import Admin from "./pages/admin/Admin";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { lazy, Suspense } from "react";
import { ThreeCircles } from "react-loader-spinner";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import CookiePolicy from "./pages/CookiePolicy";
// import TermsAndConditions from "./pages/TermsAndConditions";
// import CookieConsent from "react-cookie-consent";
// import PageNotFound from "./pages/PageNotFound";

// Lazy load components
const About = lazy(() => import("./pages/About"));
const Home = lazy(() => import("./pages/Home"));
const NewsLayout = lazy(() => import("./pages/NewsLayout"));
const News = lazy(() => import("./pages/News"));
const SingleNews = lazy(() => import("./pages/SingleNews"));
const Partner = lazy(() => import("./pages/Partner"));
const Vacancy = lazy(() => import("./pages/Vacancy"));
const Accessories = lazy(() => import("./pages/Accessories"));
const Product = lazy(() => import("./pages/products/Product"));
const Calculator = lazy(() => import("./pages/calculator/Calculator"));
const Analytics = lazy(() => import("./pages/products/Analytics"));
const Services = lazy(() => import("./pages/products/Services"));
const CareMgt = lazy(() => import("./pages/products/CareMgt"));
const ResourceMgt = lazy(() => import("./pages/products/ResourceMgt"));
const PatientMonitoring = lazy(() =>
  import("./pages/products/PatientMonitoring")
);
const SystemIntegration = lazy(() =>
  import("./pages/products/SystemIntegration")
);
const SmartRostering = lazy(() => import("./pages/products/SmartRostering"));
const DigitalTech = lazy(() => import("./pages/products/DigitalTech"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const Admin = lazy(() => import("./pages/admin/Admin"));

export let firebaseData = "";
export let db = "";
export let storage = "";
export let auth = "";

function App() {
  const firebaseConfig = {
    // apiKey: env.FIREBASE_API_KEY,
    // authDomain: env.FIREBASE_AUTH_DOMAIN,
    // projectId: env.FIREBASE_PROJECT_ID,
    // // storageBucket: env.FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
    // appId: env.FIREBASE_APP_ID,

    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,

    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  // Initialize Firebase
  firebaseData = initializeApp(firebaseConfig);
  db = getFirestore(firebaseData);
  auth = getAuth(firebaseData);
  storage = getStorage(firebaseData); // Initialize Firebase Storage here

  return (
    <div className="">
      <div className="  ">
        <Suspense
          fallback={
            <div className="w-full flex justify-center items-center h-screen   bg-white">
              <div>
                <ThreeCircles
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  size={90}
                  color="#00a99d "
                />
                <h1 className="my-5">Loading ...</h1>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/news" element={<NewsLayout />}>
              <Route index element={<News />} />
              <Route path=":newsId" element={<SingleNews />} />
            </Route>
            <Route path="/partner" element={<Partner />} />
            <Route path="/vacancy" element={<Vacancy />} />
            <Route path="/accessories" element={<Accessories />} />
            <Route path="/product" element={<Product />}>
              <Route index element={<CareMgt />} />
              <Route path="care-management" element={<CareMgt />} />
              <Route path="resource-management" element={<ResourceMgt />} />
              <Route
                path="patient-monitoring"
                element={<PatientMonitoring />}
              />
              <Route
                path="system-integration"
                element={<SystemIntegration />}
              />
              <Route path="smart-rostering" element={<SmartRostering />} />
              <Route path="digital-technology" element={<DigitalTech />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="services" element={<Services />} />
            </Route>
            <Route path="/cost-saving-calculator" element={<Calculator />} />{" "}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </div>
      <div className=" flex flex-col justify-end">
        <ChatIcon />
      </div>

      <div>
        <CookieConsent
          location="bottom"
          buttonText="Accept All"
          declineButtonText="Decline"
          enableDeclineButton
          onAccept={() => {
            console.log("Accepted cookies");
          }}
          onDecline={() => {
            console.log("Rejected cookies");
          }}
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          declineButtonStyle={{
            color: "#fff",
            fontSize: "13px",
            marginLeft: "10px",
          }}
          expires={150}
        >
          We use cookies to enhance your browsing experience, serve personalized
          ads or content, and analyze our traffic. By clicking "Accept All", you
          consent to our use of cookies{" "}
          <span style={{ fontSize: "10px" }}>
            You can accept or reject cookies.
          </span>
        </CookieConsent>
      </div>
    </div>
  );
}

export default App;
