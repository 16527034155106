import React from "react";

import chaticon from "../assests/home/chat.png";

const ChatIcon = () => {
    const message = "Hello! I need assistance."; // Default message

    const handleClick = () => {
        const url = `https://wa.me/${
            process.env.REACT_APP_WHATSAPP_NO
        }?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank");
    };

    return (
        <div
            className=" w-[15%] md:w-[10%]"
            onClick={handleClick}
            style={{
                cursor: "pointer",
                position: "fixed",
                bottom: "20px",
                right: "20px",
            }}
        >
            {/* <FaWhatsapp size={50} color="#25D366" /> */}
            <div className=" w-full  flex flex-col justify-end">
                <div className=" flex flex-row justify-end">
                    {" "}
                    <img
                        src={chaticon}
                        alt=""
                        className="w-[100%] md:w-[50%]"
                    />
                </div>
            </div>
        </div>
    );
};

export default ChatIcon;
