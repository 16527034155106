import React from "react";
import notFound from "../assests/404-1.gif";
import { Link } from "react-router-dom";
const PageNotFound = () => {
    return (
        <div className=" h-screen ">
            <div className="  h-full bg-gray-600 w-full  md:h-full  z-50 pt-10">
                <div className="flex flex-col md:flex-row mx-auto justify-between items-center gap-2 md:mt-10 w-full  md:w-[80%] ">
                    <div className="w-2/3 md:w-1/2  rounded-md ">
                        <img
                            src={notFound}
                            alt=" 404 not found"
                            className="w-full h-full rounded-2xl "
                        />
                    </div>
                    <div className="w-full md:w-1/2 text-gray-400 md:text-white flex flex-col items-center">
                        <h1 className="text-center text-2xl md:text-4xl font-bold text-white  mb-5">
                            404 - Page Not Found
                        </h1>

                        <p className="text-center text-sm  w-2/3">
                            The page you're looking for might have been removed,
                            had its name changed, or is temporarily unavailable.
                        </p>

                        <div className="flex justify-center mt-10">
                            <Link
                                to="/"
                                className="px-5 py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                            >
                                Back to Home
                            </Link>
                        </div>

                        <p className="text-center  mt-5 w-2/3">
                            &copy; Plex Medical Ltd (C103111) 28, Sir Luigi
                            Camilleri Street Sliema, Malta, SLM 1848
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
